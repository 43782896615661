export const TicketingClosed = () => {
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-xl font-medium pb-2">
        Presale closed. You can still get tickets at the door for 15 EUR.
      </h1>
      <div>
        <h3 className="mt-4 text-lg font-medium">Location</h3>
        <p className="my-2">
          Stadtbahnbogen 114 Alexanderplatz (other side of ALX Späti), see image below:
        </p>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/wuest-affairs.appspot.com/o/public%2Flocation_wa.jpeg?alt=media&token=f5e4bd13-a194-4cc9-888c-ec9820de9a48"
          className="max-w-[800px] w-full rounded-md"
        />
      </div>
    </div>
  );
};
