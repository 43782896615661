import { TicketingClosed } from './TicketingClosed';
import { TicketsPage } from './Tickets';

export const Ticketing = () => {
  if (new Date().getTime() > new Date('2024-11-30T00:05:00.291Z').getTime()) {
    return <TicketingClosed />;
  }

  return <TicketsPage />;
};
