import { FirebaseClient } from '@/api/firebaseClient';
import { QUERY_KEYS, ticketTypeQuery } from '@/api/queries';
import { useInvalidatingMutation } from '@/hooks/useInvalidatingMutation';
import { BuyTicketRequest, TicketType } from '@packages/types';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Input,
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@packages/ui';

export const TicketsPage = () => {
  const navigate = useNavigate();

  const client = new FirebaseClient();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [ticketType, setTicketType] = useState('');

  const { data: ticketTypes, isLoading } = useQuery(ticketTypeQuery());
  const { mutateAsync: buyTicket, isPending } = useInvalidatingMutation([QUERY_KEYS.TICKETS], {
    mutationFn: async (data: BuyTicketRequest) => client.buyTicket(data),
  });

  const allTicketTypes = useMemo(
    () => (ticketTypes ?? []).slice().sort((a, b) => a.price - b.price),
    [ticketTypes],
  );
  const availableTicketTypes = useMemo(() => {
    return allTicketTypes.filter((t) => t.available > 0) ?? [];
  }, [allTicketTypes]);

  const selectedTicketType = useMemo<TicketType>(() => {
    const type = availableTicketTypes.find((t) => t.name === ticketType);
    return type ?? availableTicketTypes[0];
  }, [availableTicketTypes, ticketType]);

  useEffect(() => {
    if (!ticketType && availableTicketTypes.length) {
      setTicketType(availableTicketTypes[0].name);
    }
  }, [availableTicketTypes, ticketType]);

  const onSubmit = async () => {
    setError('');
    const form: HTMLFormElement | null = document.getElementById(
      'ticketForm',
    ) as HTMLFormElement | null;
    if (!form) return;
    const isValid = form.reportValidity();
    if (!isValid) return;

    if (!email) {
      setError('Please enter an email.');
      return;
    }
    if (!name) {
      setError('Please enter your name.');
      return;
    }
    try {
      const result = await buyTicket({ ticketType, name, email });
      navigate(`/tickets/${result.data.ticket.id}?success`);
    } catch (e) {
      console.error('Error buying ticket', e);
      setError('Ticket could not be bought.');
    }
  };

  if (isLoading) {
    return null;
  }

  if (!availableTicketTypes.length) {
    return <div>There are no tickets available.</div>;
  }

  return (
    <div className="flex flex-col items-center">
      <div>
        <p className="text-muted-foreground/80 text-sm">
          Wüst & Wild and Family Affairs are two Berlin collectives, each organizing their own
          festivals, that came together to collaboratively host cozy club events: from friends, for
          friends. 2 Floors (uplifting/trance + melodic/dark) & plushy vibes included. Location is
          in central Berlin + will be announced the day before the event.
        </p>
      </div>
      <div className="mt-1 self-start text-sm">
        <a
          href="https://ra.co/events/2017079"
          className="text-warning hover:text-warning/80 inline-flex items-center space-x-2">
          <svg className="h-3" viewBox="0 0 83 40" aria-label="RA logo">
            <title>RA</title>
            <g fill="none" fillRule="evenodd">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M82.092 32.018c.556-.533.908-1.28.908-2.113 0-.802-.38-1.523-.9-2.051L58.665 4.3l-7.073 7.11 18.45 18.543h-26.14c-1.278-.038-2.29-.469-3.147-1.304l-11.73-11.788a6.828 6.828 0 00-4.689-1.888l-.017.001H10.004v-4.92h14.825c2.938.002 5.559 1.21 7.48 3.15l8.749 8.793 7.073-7.11-8.92-8.963C35.485 2.234 30.45 0 24.805 0H0v25.027h20.978v.002a4.919 4.919 0 013.486 1.48L35.95 38.053A6.74 6.74 0 0040.449 40h31.733a4.911 4.911 0 003.423-1.45l6.491-6.524-.004-.008"
                fill="currentColor"></path>
            </g>
          </svg>
          <span>Find us on Resident Advisor</span>
        </a>
      </div>
      <h1 className="text-xl font-medium pb-2 mt-4">Buy your ticket now</h1>

      <form id="ticketForm" className="flex flex-col w-full gap-2 p-4 max-w-[400px] max-w-screen">
        <Select
          value={ticketType}
          onValueChange={(value: string) => {
            setTicketType(value);
          }}>
          <SelectTrigger className="w-full bg-white/10">
            <SelectValue placeholder={selectedTicketType.label} />
          </SelectTrigger>
          <SelectContent className="">
            {allTicketTypes.map((type) => (
              <SelectItem key={type.name} value={type.name} disabled={type.available <= 0}>
                {type.label} {type.available <= 0 ? '(sold out)' : null}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {ticketType ? (
          <>
            <Input
              className="p-2 border rounded w-full"
              type="email"
              required
              placeholder="Email"
              value={email}
              onChange={(input) => setEmail(input.target.value)}
            />

            <Input
              className="p-2 border rounded w-full"
              name="name"
              required
              type="text"
              value={name}
              onChange={(input) => setName(input.target.value)}
              placeholder="First and last name"
            />

            <div className="cart mt-8 mb-4 flex flex-col text-lg">
              <span>Total price</span>
              <span className="font-bold text-3xl">{selectedTicketType.price} EUR</span>
            </div>

            <Button
              disabled={isPending}
              onClick={() => onSubmit()}
              className="px-4 py-4 rounded text-white">
              Buy Ticket
            </Button>
          </>
        ) : null}

        {error && <div className="text-red-500">{error}</div>}
      </form>
    </div>
  );
};
